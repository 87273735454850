import { Card } from "primereact/card";


/**
 * User login form
 */
export default function PageConstruction() {

  //const canSubmit = username.length > 0 && password.length > 0;


  return (
    <>
    <Card className="construction-card">
        <h1>En construcción</h1>
        <p>Esta página está actualmente en construcción. Por favor vuelve a consultar más tarde.</p>
    </Card>
    </>
  );
}
